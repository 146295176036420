@font-face {
    font-family: "brand";
    src: url("../assets/fonts/COOPBL.ttf");
}

@font-face {
    font-family: "light";
    src: url("../assets/fonts/Inter-Light.ttf");
}

@font-face {
    font-family: "reg";
    src: url("../assets/fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: "medium";
    src: url("../assets/fonts/Inter-Medium.ttf");
}


@font-face {
    font-family: "bold";
    src: url("../assets/fonts/Inter-Bold.ttf");
}

@font-face {
    font-family: "semi-bold";
    src: url("../assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
    font-family: "extreme-bold";
    src: url("../assets/fonts/Inter-ExtraBold.ttf");
}

:root {
    --fontSmall: 12px;
    --fontRegular: 14px;
    --fontMedium: 16px;
    --fontLarge: 24px;

    --radiusSmall: 4px;
    --radiusRegular: 8px;
    --radiusMedium: 12px;
    --radiusLarge: 16px;

    --paddingSmall: 4px;
    --paddingRegular: 8px;
    --paddingMedium: 14px;
    --padding: 12px;
    --paddingLarge: 16px;
    --paddingExtraLarge: 32px;

    --contentWidth: 980px;

    --color: white;
    --secondaryColor: rgb(161, 161, 161);
    --dark600: #1b1e22;
    --dark500: #212529;
    --dark400: #343a40;
    --dark300: #30353a;
    --gray10: #343a40;
    --gray100: rgb(234, 234, 234);
    --gray200: rgb(215, 213, 213);
    --gray700: rgb(91, 91, 91);
    --gray800: rgb(44, 44, 44);
    --green700: #2dc653;
    --green600: #1c997a;
    --green200: #113a39;
    --green10: #1d997a30;
    --green: #2dc653;
    --yellow600:rgb(209 198 33);
    --yellow200: #3a3411;
    --yellow10: #99911d30;
    --yellow700: #fb8500;
    --red10: #2d1f1f;
    --red200: #3a1111;
    --red600: #e62b2b;
    --blue: rgb(0,122,255);
    --blue10: #1f252d;
    --blue200: #111d3a;
    --blue600: #1c4699;
    --borderColor500: rgb(49, 52, 54);
}

html {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

* {
    box-sizing: border-box;
    text-decoration: none;
}

body {
    background: var(--dark600);
    color: var(--color);
    font-family: 'reg';
    font-size: var(--fontRegular);
    margin: auto;
    overflow: hidden;
}

a {
    color: var(--blue);
}

.ant-popover-content {
    .ant-popover-inner {
        background: var(--dark500);
    }
    .ant-popconfirm-message-text {
        * {
            color: var(--color) !important;
        }
    }

}
.ant-popover-arrow::before {
    background: var(--dark500) !important;
}

th {
    background: var(--dark300) !important;
}

tr, .ant-table-container, th:first-child {
    border-start-start-radius: var(--radiusLarge) !important;
}

.ant-table {
    border-radius: var(--radiusLarge) !important;
    border-start-start-radius: var(--radiusLarge) !important;
    border-start-end-radius: var(--radiusLarge) !important;
    overflow: hidden;
}

.table-shimmer {
    .ant-table {
        background: #18191d !important;
    }
}

th:last-child {
    border-start-end-radius: var(--radiusLarge) !important;
}

.ant-table-cell-row-hover {
    background: var(--dark600) !important;
}

tr:last-child {
    td {
        border-bottom: 0 !important;
    }
}

.ant-pagination-item {
    border-radius: 6px !important;
}

.ant-pagination-item-active {
    background-color: var(--dark300) !important;
    border: 1px solid var(--gray700) !important;
}

.ant-dropdown-menu {
    border-radius: 8px !important;
}

.ant-dropdown-menu-item {
    gap: var(--padding);
}
.ant-dropdown-menu-item-danger:hover  {
    background: var(--red10) !important;
    color: var(--red600) !important;
}
.ant-dropdown-menu-item-divider{
    background-color: var(--gray700) !important;
}
.ant-dropdown-menu-item-active {
    background: var(--dark500) !important;
}
.ant-steps-item-wait {
  .ant-steps-icon{
    color: var(--color) !important;
  }  
}

.ant-steps-item-active {
    .steps-circle {
        background-color: var(--yellow10) !important;
    }
    svg {
        color: var(--yellow600) !important;
    }
}

.ant-steps-item-finish {
    .ant-steps-item-title {
        color: var(--green600) !important;
        &::after {
            background-color: var(--green600) !important;
        }
    }
    .steps-circle {
        background-color: var(--green10) !important;
    }
    svg {
        color: var(--green600) !important;
    }
}

.ant-btn-primary {
    box-shadow: none;
}

.ant-modal-footer {
    .ant-btn-primary, .ant-btn-default {
        font-family: reg !important;
        border-radius: var(--radiusRegular);
    padding: 2px 12px !important;
    }
    
    .ant-btn-primary {
        background: var(--blue);
    }

    .ant-btn-default {
        background: transparent;
        border-color: var(--dark400) !important;
        color: white !important;
    }
    
}

.ant-modal-mask {
    backdrop-filter: blur(20px) !important;
}

.img-crop-control {
    button {
        color: var(--color);
    }
    .ant-slider-rail {
        background: var(--dark600);
    }
}